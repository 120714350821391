export const normatividad_item = [
    {
        "normatividad_item_nombre" : "Contraloria social 2024",
        "normatividad_item_descripcion" : "Programa Apoyo a Instituciones Estales de Cultura AIEC 2024",
        "normatividad_item_url" : "/AIEC"
    },
    {
        "normatividad_item_nombre" : "Contraloria social 2023",
        "normatividad_item_descripcion" : "Comité de Contraloría Social del Programa Música para el Pueblo",
        "normatividad_item_url" : "/Csocial"
    },
    {
        "normatividad_item_nombre" : "Contraloria social 2022",
        "normatividad_item_descripcion" : "Comité de Contraloría Social del Programa ReactivArte",
        "normatividad_item_url" : "/Csociale"
    },
    {
        "normatividad_item_nombre" : "Comité de Ética y Prevención de Conflictos de Interés",
        "normatividad_item_descripcion" : "Comité de Ética y Prevención de Conflictos de Interés",
        "normatividad_item_url" : "/Cetica"
    },
     {
        "normatividad_item_nombre" : "PID  2023-2028",
        "normatividad_item_descripcion" : "Programa Institucional de Desarrollo",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/1.6 Programa Institucional de Desarrollo CECULTAH  20_dic_2023.pdf"
    },
    {
        "normatividad_item_nombre" : "Decreto de Creación CECULTAH",
        "normatividad_item_descripcion" : "Decreto que reforma diversas disposiciones del Decreto que creó al Consejo Estatal para la Cultura y las Artes de Hidalgo",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Decreto de Creacion CECULTAH.pdf"
    },
    {
        "normatividad_item_nombre" : "Estatuto Orgánico CECULTAH",
        "normatividad_item_descripcion" : "Acuerdo que contiene el Estatuto Orgánico del Consejo Estatal para la Cultura y las Artes de Hidalgo",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Estatuto Organico CECULTAH.pdf"
    },
   {
        "normatividad_item_nombre" : "Manual de Organización CECULTAH",
        "normatividad_item_descripcion" : "Manual del Organización del Consejo Estatal para la Cultura y las Artes de Hidalgo",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Manual de Organizacion CECULTAH.pdf"
    },
    {
        "normatividad_item_nombre" : "Manual de Procedimientos CECULTAH",
        "normatividad_item_descripcion" : "Manual de Procedimientos del Consejo Estatal para la Cultura y las Artes de Hidalgo",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Manual de Procedimientos CECULTAH.pdf"
    },
    {
        "normatividad_item_nombre" : "Norma y Bases para cancelar adeudos CECULTAH",
        "normatividad_item_descripcion" : "Acuerdo que contiene las Normas y Bases para Cancelar Adeudos a cargo de terceros y a favor del Consejo Estatal para la Cultura y las Artes de Hidalgo",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Normas y bases para cancelar adeudos CECULTAH.pdf"
    },
]


 