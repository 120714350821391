export const transparencia_item = [
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_descripcion" : "Articulo 69 Ley de Transparencia",
        "transparencia_item_url" : "Rubros"
    },
    
     //   "transparencia_item_nombre" : "Art. 70",
     //   "transparencia_item_descripcion" : "Articulo 70 Ley de Transparencia",
     //   "transparencia_item_url" : "/Art70"
    
    {
        "transparencia_item_nombre" : "Rubros anteriores",
        "transparencia_item_descripcion" : "Articulo 69 Ley de Transparencia 2022, 2021, 2020",
        "transparencia_item_url" : "Rubros/RubrosHistoricos"
    },
    {
        "transparencia_item_nombre" : "COCODI",
        "transparencia_item_descripcion" : "Comité de Control y Desempeño Institucional del CECULTAH",
        "transparencia_item_url" : "/Cocodi"
    },
    {
        "transparencia_item_nombre" : "Sistema Institucional de Archivos",
        "transparencia_item_descripcion" : "Conjunto de registro, procedimientos, criterios, estructuras y funciones que desarrolla y sustenta la actividad archivÍstica del CECULTAH",
        "transparencia_item_url" : "/SistemaInstitucionalArchivos"
    },
    
]