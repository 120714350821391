export const ejerciciosAnteriores = [
    {
        "anio" : "2023",
        "descripcion" : "Convocatorias, Licitaciones y Actas (2023)",
        "trimestres" : [
            {
               
                 "zip" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2023/procedimientos_2023.zip",
            },
        ]
    },
    {
        "anio" : "2022",
        "descripcion" : "Convocatorias, Licitaciones y Actas (2022)",
        "trimestres" : [
            {
               
                 "zip" : "/error.zip",
            },
        ]
    },
    {
        "anio" : "2021",
        "descripcion" : "Convocatorias, Licitaciones y Actas (2021)",
        "trimestres" : [
            {
               
               "zip" : "/error.zip",
            }
        ]
    },
    {
        "anio" : "2020",
        "descripcion" : "Convocatorias, Licitaciones y Actas (2020)",
        "trimestres" : [
            {
               
               "zip" : "/error.zip",
            }
        ]
    },
    {
        "anio" : "2019",
        "descripcion" : "Convocatorias, Licitaciones y Actas (2019)",
        "trimestres" : [
            {
               
               "zip" : "/error.zip",
            }
        ]
    },
    {
        "anio" : "2018",
        "descripcion" : "Convocatorias, Licitaciones y Actas (2018)",
        "trimestres" : [
            {
               
               "zip" : "/error.zip",
            }
        ]
    },
]