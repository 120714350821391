export const DisciplinaFinancieraAnual = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F1_Estado%20de%20Situaci%C3%B3n%20Financiera_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Estado%20de%20Situaci%C3%B3n%20Financiera%20Detallado.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/ESTADO%20DE%20SITUACION%20FINANCIERA%20DETALLADO.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/ESTADO%20SITUACION%20FINANCIERA%20DETALLADO.xlsx"
                    }
                ]
            },
        ],
    }
];
export const DeudaPpasivos = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F2_Informe%20Analitico%20Deuda%20POP_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Informe%20analitico%20de%20la%20Deuda%20y%20Otros%20Pasivos.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/INFORME%20ANALITICO%20DE%20LA%20DEUDA%20DETALLADO.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/INFORME%20ANALITICO%20DE%20LA%20DEUDA.xlsx"
                    }
                ]
            },
        ],
    }
];
export const ObrigacionesDiferentesFinanciamientoTrim = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F3_Iinforme%20Analitico%20Obligaciones%20DiferentesF_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Informe%20analitico%20de%20Obligaciones%20de%20Diferentes%20Financiamientos.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/INFORME%20ANALITICO%20DE%20OBLIGACIONES%20DIFERENTES%20F3.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/INFORME%20ANALITICO%20OBLIGACIONES%20DIFERENTES.xlsx"
                    }
                ]
            },
        ],
    }
];
export const BalancePresupuestarioTrim = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F4_Balance%20Presupuestario_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Balance%20Presupuestario.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/BALANCE%20PRESUPUESTARIO%20F4.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/BALANCE%20PRESUPUESTARIO.xlsx"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaliticoIngresosDetalladoTrim  = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F5_Estado%20Analitico%20Iingresos%20Detallado_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Estado%20Analitico%20de%20Ingresos%20Detallado.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/ESTADO%20ANALITICO%20DE%20INGRESOS%20F5.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/ESTADO%20ANALITICO%20DE%20INGRESOS.xlsx"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasObjetoGastoCapConcepTrim  = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F6a_EAEPED_Clasificaci%C3%B3n%20Oobjeto%20Gasto_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Estado%20Analitico%20del%20Presupuesto%20de%20egresos%20por%20Objeto%20del%20Gasto%20Detallado.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/ESTADO%20ANALITICO%20EGRESOS%20CLASIF.%20OBJETO%20DEL%20GASTO%20F6a.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/ESTADO%20ANALITICO%20OBJETO%20DEL%20GASTO%20DETALLADO%20F6A.xlsx"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasAdminTrim  = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F6b_EAEPED_Clasificaci%C3%B3n%20Administrativa_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Estado%20Analitico%20del%20Presupuesto%20de%20egresos%20Clasificaci%C3%B3n%20Administrativa%20Detallado.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/ESTADO%20CLASIFICACIOPN%20CLASIF.%20ADMINISTRATIVA%20F6b.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/ESTADO%20ANALITICO%20CLASIF%20ADMINISTRATIVA.xlsx"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasFuncionalFinalidadFuncionTrim  = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F6c_EAEPED_Clasificacion%20Funcional_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Estado%20Analitico%20del%20Presupuesto%20de%20egresos%20por%20Clasificaci%C3%B3n%20Funcional%20Detallado.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/ESTADO%20ANALITICO%20DE%20EGRESOS%20CLASIF.%20FUNCIONAL%20F6c.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/EDO%20ANAL%20CLASIF%20FUNCIONAL.xlsx"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasFuncionalServiciosPersonalesCategoriaTrim  = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F6d_EAEPED_Clasificaci%C3%B3n%20Servicios%20Profesionales_3132022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/Estado%20Analitico%20del%20Presupuesto%20de%20egresos%20Clasificaci%C3%B3n%20de%20Servicios%20Personales%20Detallado.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/ley_financiera/ESTADO%20ANALITICO%20DE%20EGRESOS%20CLASIF%20SERVICIOS%20PERSONALES%20F%206d.xlsx"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/EST%20ANA%20CLAS%20SERV%20PERSONALES.xlsx"
                    }
                ]
            },
        ],
    }
];
export const GuiaCumpLeyDFEntidadesTrim  = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Situacion%20Financiera.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Situacion%20Financiera.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Situacion%20Financiera.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Situacion%20Financiera.PDF"
                    }
                ]
            },
        ],
    }
];
export const EstudActuarialesTrim  = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/F8_IEA_3092021.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/2do_trim/F8_IEA_3092021.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Situacion%20Financiera.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2023/1er_trim/Estado%20de%20Situacion%20Financiera.PDF"
                    }
                ]
            },
        ],
    }
];
export const ProyeccionesIngresos  = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/proyecciones%20ingresos%202022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/PROYECCIONES%20DE%20INGRESOS.xlsx"
                    }
                ]
            },
        ],
    }
];
export const ProyeccionesEgresos  = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/ley_disciplina_financiera/1er_trim/Proyecciones%20%20de%20Egresos%202022.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/PROYECCIONES%20DE%20EGRESOS.xlsx"
                    }
                ]
            },
        ],
    }
];
export const ResultadosIngresos  = [
    {
        "anio" : "* 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/RESULTADOS%20DE%20INGRESOS.xlsx"
                    }
                ]
            },
        ],
    }
];
export const ResultadosEgresos  = [
    {
        "anio" : "- 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "pdf" : ""
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/ley_disciplina_f/RESULTADOS%20DE%20EGRESOS.xlsx"
                    }
                ]
            },
        ],
    }
];




export const disciplinaFinancieraAnualCollapce = [
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%20INFORMACION%20CONTABLE/01%20EA.PDF",
            }
        ]
    }
];