import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './sistemainstitucionalarchivos.css';
import { sistemainstitucionalarchivos_item } from '../../json/sistemainstitucionalarchivosItem';

const SistemaInstitucionalArchivos = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container sistemainstitucionalarchivos-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='sistemainstitucionalarchivos_title'>
                    <h1>Sistema Institucional de Archivos</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                sistemainstitucionalarchivos_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 sistemainstitucionalarchivos_item'>
                        <Link to={ item.sistemainstitucionalarchivos_item_pdf } target='_blank'>{ item.sistemainstitucionalarchivos_item_nombre }</Link>
                        <p>{ item.sistemainstitucionalarchivos_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default SistemaInstitucionalArchivos