import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './invitacion.css';

const Invitacion = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container invitacion-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='invitacion_title'>
                    <h1>Invitación a cuando menos tres personas</h1>
                </div>
            </div>
        </div>

        <div className='row'>

          <div class="accordion" id="accordionExample">

          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-FGPAR-2023-INV-002"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-FGPAR-2023-INV-002" aria-expanded="false" aria-controls="collapseOneCECULTAH-FGPAR-2023-INV-002">CECULTAH-FGPAR-2023-INV-002 | Mantenimiento del centro cultural regional de Zimapan</button></h2>
              <div id="collapseOneCECULTAH-FGPAR-2023-INV-002" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-FGPAR-2023-INV-002" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-002/CECULTAH-FGPAR-2023-INV-002-%28Convocatoria%29.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-002/CECULTAH-FGPAR-2023-INV-002-Junta de Aclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-002/CECULTAH-FGPAR-2023-INV-002-(Apertura de propuestas).pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-002/CECULTAH-FGPAR-2023-INV-002-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-FGPAR-2023-INV-001"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-FGPAR-2023-INV-001" aria-expanded="false" aria-controls="collapseOneCECULTAH-FGPAR-2023-INV-001">CECULTAH-FGPAR-2023-INV-001 | Rehabilitación y Mantenimiento del Teatro Guillermo Romo de Vivar</button></h2>
              <div id="collapseOneCECULTAH-FGPAR-2023-INV-001" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-FGPAR-2023-INV-001" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-001/CECULTAH-FGPAR-2023-INV-001-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-001/CECULTAH-FGPAR-2023-INV-001-JuntadeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-FGPAR-2023-INV-001/CECULTAH-FGPAR-2023-INV-001-Apertura de propuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://cecultah.hidalgo.gob.mx/temporal/2023/procedimientos/CECULTAH-FGPAR-2023-INV-001/CECULTAH-FGPAR-2023-INV-001-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

           

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneIO-72-009-913030980-N-1-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneIO-72-009-913030980-N-1-2023" aria-expanded="false" aria-controls="collapseOneIO-72-009-913030980-N-1-2023">IO-72-009-913030980-N-1-2023 | CENTRO CULTURAL REGIONAL DE LA SIERRA OTOMÍ-TEPEHUA EN LA LOCALIDAD DE TENANGO DE DORIA DEL ESTADO DE HIDALGO</button></h2>
              <div id="collapseOneIO-72-009-913030980-N-1-2023" class="accordion-collapse collapse" aria-labelledby="headingOneIO-72-009-913030980-N-1-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">  
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/IO-72-009-913030980-N-1-2023/IO-72-009-913030980-N-1-2023-Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/IO-72-009-913030980-N-1-2023/IO-72-009-913030980-N-1-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/IO-72-009-913030980-N-1-2023/IO-72-009-913030980-N-1-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/IO-72-009-913030980-N-1-2023/IO-72-009-913030980-N-1-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-ITP-04-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-ITP-04-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-ITP-04-2023">CECULTAH-ITP-04-2023 | Mantenimiento de fachada del Cuartel del Arte</button></h2>
              <div id="collapseOneCECULTAH-ITP-04-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-ITP-04-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-04-2023/CECULTAH-ITP-04-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-04-2023/CECULTAH-ITP-04-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-04-2023/CECULTAH-ITP-04-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-04-2023/CECULTAH-ITP-04-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>
  
                
          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-ITP-03-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-ITP-03-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-ITP-03-2023">CECULTAH-ITP-03-2023 | Adquisición de Vestuario de la Compañia de Danza del Centro de las Artes de Hidalgo</button></h2>
              <div id="collapseOneCECULTAH-ITP-03-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-ITP-03-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-03-2023/CECULTAH-ITP-03-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-03-2023/CECULTAH-ITP-03-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-03-2023/CECULTAH-ITP-03-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-03-2023/CECULTAH-ITP-03-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>

          
          <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-ITP-02-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-ITP-02-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-ITP-02-2023">CECULTAH-ITP-02-2023 | Equipamiento para fortalecer actividades artisticas en los Centros Culturales para artes plasticas, musica, danza y teatro</button></h2>
              <div id="collapseOneCECULTAH-ITP-02-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-ITP-02-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-02-2023/CECULTAH-ITP-02-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-02-2023/CECULTAH-ITP-02-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-02-2023/CECULTAH-ITP-02-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-02-2023/CECULTAH-ITP-02-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>


           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOneCECULTAH-ITP-01-2023"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOneCECULTAH-ITP-01-2023" aria-expanded="false" aria-controls="collapseOneCECULTAH-ITP-01-2023">CECULTAH-ITP-01-2023 | Arrendamiento de Backline (Equipo electrónico de audio, iluminación, sonido, mesas y sillas)</button></h2>
              <div id="collapseOneCECULTAH-ITP-01-2023" class="accordion-collapse collapse" aria-labelledby="headingOneCECULTAH-ITP-01-2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                       <li class="list-group-item">Convocatoria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-01-2023/CECULTAH-ITP-01-2023_Convocatoria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Junta de Aclaraciones<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-01-2023/CECULTAH-ITP-01-2023-JuntaDeAclaraciones.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Apertura de Propuestas<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-01-2023/CECULTAH-ITP-01-2023-AperturaPropuestas.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Fallo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/procedimientos/CECULTAH-ITP-01-2023/CECULTAH-ITP-01-2023-ActaFallo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

          </div>

        </div>
    </div>
  )
}

export default Invitacion