export const ultimasNoticias = [
    {
        "imagen" : "https://taquilla.cecultah.gob.mx/wp-content/uploads/2024/08/header-boletos-20-sept-copy.jpg",
        "url" : "https://taquilla.cecultah.gob.mx/events/concierto-fiesta-mexicana-2024/",
        "titulo" : "Concierto Fiesta Mexicana 2024",
        "fecha" : "19 de Septiembre del 2024"
    },
    {
        "imagen" : "https://culturahidalgo.gob.mx/wp-content/uploads/2024/06/convocatoria1_2237945a81-1024x460.png",
        "url" : "https://s-contraloria.hidalgo.gob.mx/descargables/Convocatoria%20Transparencia%20en%20Corto%202024%20Modificado.pdf",
        "titulo" : "Convocatoria del Premio Nacional de Transparencia en Corto 2024",
        "fecha" : "2024"
    },
       {
        "imagen" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/aiec.jpg",
        "url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/aiec completa.jpg",
        "titulo" : "Apoyo a Instituciones Estatales de Cultura AIEC 2024",
        "fecha" : ""
    },


   /*  {
        "imagen" : "../assets/imgs/fiestam4.jpg",
        "url" : "https://taquilla.cecultah.gob.mx/events/concierto-fiesta-mexicana-con-la-oseh-2023/",
        "titulo" : "Fiesta Mexicana",
        "fecha" : "miercoles 20 de septiembre del 2023, 18:00 horas"
    },
    {
        "imagen" : "../assets/imgs/fiestam3.jpg",
        "url" : "https://taquilla.cecultah.gob.mx/events/concierto-fiesta-mexicana-con-la-oseh-2023/",
        "titulo" : "Fiesta Mexicana",
        "fecha" : "miercoles 20 de septiembre del 2023, 18:00 horas"
    },
    {
        "imagen" : "../assets/imgs/fiestam2.jpg",
        "url" : "https://taquilla.cecultah.gob.mx/events/concierto-fiesta-mexicana-con-la-oseh-2023/",
        "titulo" : "Fiesta Mexicana",
        "fecha" : "miercoles 20 de septiembre del 2023, 18:00 horas"
    },
    {
        "imagen" : "../assets/imgs/fiestam1.jpg",
        "url" : "https://taquilla.cecultah.gob.mx/events/concierto-fiesta-mexicana-con-la-oseh-2023/",
        "titulo" : "Fiesta Mexicana",
        "fecha" : "miercoles 20 de septiembre del 2023, 18:00 horas"
    },
    {
        "imagen" : "../assets/imgs/movilidadArtistica.jpg",
        "titulo" : "Apoyo a Instituciones Estatales de Cultura AIEC 2023",
        
    },*/
]