export const tramites_item = [
    {
        "tramites_item_nombre" : "Programa Escolarizado de Educación artística incial del Centro de las Artes de Hidalgo",
        "tramites_item_descripcion" : "Curso escolarizado de 3 a 4 años en las disciplinas de música, danza, teatro y artes visuales",
        "tramites_item_url" : "https://ruts.hidalgo.gob.mx/ver/566"
    },
    {
        "tramites_item_nombre" : "Solicitud de Beca para alumnos del Centro de las Artes de Hidalgo",
        "tramites_item_descripcion" : "Brindar apoyo con una beca del 25%, 50% o 75% para que los alumnos continúen con sus estudios en el Centro de las Artes de Hidalgo en los ciclos de Educación Artística Inicial (Sistema escolarizado) y en los talleres libres cuya condición administrativa lo permita",
        "tramites_item_url" : "https://ruts.hidalgo.gob.mx/ver/7059"
    },
    {
        "tramites_item_nombre" : "Talleres Artisticos especializados del Centro de las Artes de Hidalgo",
        "tramites_item_descripcion" : "Talleres de formación especializada en las disciplinas de música, danza, teatro, artes visuales y literatura.",
        "tramites_item_url" : "https://ruts.hidalgo.gob.mx/ver/585"
    },
    {
        "tramites_item_nombre" : "Talleres artísticos libres del Centro de las Artes de Hidalgo",
        "tramites_item_descripcion" : "Talleres de formación inicial para desarrollar habilidades en las disciplinas de música, danza, teatro, artes visuales y literatura",
        "tramites_item_url" : "https://ruts.hidalgo.gob.mx/ver/583"
    },
]