export const adquisiciones_item = [
    {
        "adquisiciones_item_nombre" : "Programa Anual de Adquisiciones, Arrendamientos y Servicios del Consejo Estatal para la Cultura y las Artes de Hidalgo 2023",
        "adquisiciones_item_url" : "/Paaas",
    },
    {
        "adquisiciones_item_nombre" : "Licitaciones Públicas Nacionales",
        "adquisiciones_item_url" : "/Licitaciones",
    },
    {
        "adquisiciones_item_nombre" : "Invitación a cuando menos tres personas",
        "adquisiciones_item_url" : "/Invitacion",
    },
    {
        "adquisiciones_item_nombre" : "Información Ejercicios Anteriores",
        "adquisiciones_item_url" : "/EjerciciosAnteriores"
    },
]