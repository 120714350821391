export const servicios_item = [
    {
        "servicios_item_nombre" : "Banda Sinfónica del Estado de Hidalgo",
        "servicios_item_descripcion" : "Presentaciónes de la Banda Sinfónica del Estado de Hidalgo",
        "servicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/529"
    },
    {
        "servicios_item_nombre" : "Los Bohemios",
        "servicios_item_descripcion" : "Presentación del Grupo Los Bohemios",
        "servicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/10602"
    },
    {
        "servicios_item_nombre" : "Renta de espacios culturales",
        "servicios_item_descripcion" : "Renta de espacios y recintos a cargo del CECULTAH para llevar a cabo actividades de caracter cultural",
        "servicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/473"
    },
    {
        "servicios_item_nombre" : "Taquilla Cultural",
        "servicios_item_descripcion" : "Taquilla en Línea para pago y generación de boletos que le permite a las y los ciudadanos, el acceso a eventos artísticos y culturales publicados en la Cartelera Cultural Digital institucional",
        "servicios_item_url" : "https://ruts.hidalgo.gob.mx/ver/8035"
    },
    {
        "servicios_item_nombre" : "Tienda Margarita Michelena",
        "servicios_item_descripcion" : "Consulta y adquiere una gran variedad de títulos de la producción editorial de la Secretaría de Cultura del Estado de Hidalgo",
        "servicios_item_url" : "https://tienda.cecultah.gob.mx/"
    }
]