export const Armonizacion = [
    {
        "titulo" : "Información Contable",
        "subtitulo" : [
            {
                "informacionTitulo" : "a) Estado de Actividades"
            },
            {
                "informacionTitulo" : "b) Estado de Situación Financiera"
            },
            {
                "informacionTitulo" : "c) Estado de Variación en la Hacienda Pública"
            },
            {
                "informacionTitulo" : "d) Estado de Cambios en la Situación Financiera"
            },
            {
                "informacionTitulo" : "e) Estado de Flujos de Efectivo"
            },
            {
                "informacionTitulo" : "f) Informe de Pasivos Contingentes"
            },
            {
                "informacionTitulo" : "g) Notas a los Estados Financieros"
            },
            {
                "informacionTitulo" : "h) Estado Analítico del Activo"
            },
            {
                "informacionTitulo" : "i) Estado Analítico de la Deuda y Otros Pasivos"
            },
            {
                "informacionTitulo" : "j) Conciliacion entre los Ingresos Presupuestarios y Contables"
            },
            {
                "informacionTitulo" : "k) Conciliacion entre los Egresos Presupuestarios y los Gastos Contables"
            },
        ]
    },
    {
        "titulo" : "Información Presupuestaria",
        "subtitulo" : [
            {
                "informacionTitulo" : "a) Estado Analítico de Ingresos"
            },
            //{
            //    "informacionTitulo" : "b) Estado Analítico Presupuesto Egresos CE"
            //},
            {
                "informacionTitulo" : "b) 1. Estado Analítico Presupuesto Egresos Clasificacion Administrativa"
            },
            {
                "informacionTitulo" : "b) 2. Estado Analítico Presupuesto Egresos Clasificacion Economica"
            },
            {
                "informacionTitulo" : "b) 3. Estado Analítico Presupuesto Egresos Clasificación por Objeto del Gasto"
            },
             {
                "informacionTitulo" : "b) 4. Estado Analítico Presupuesto Egresos Clasificación Funcional"
            },
            {
                "informacionTitulo" : "c) Endeudamiento Neto"
            },
            {
                "informacionTitulo" : "d) Intereses de la Deuda"
            },
            {
                "informacionTitulo" : "e) Flujo de fondos (Indicadores de Postura Fiscal"
            },
        ]
        
    },
    {
        "titulo" : "Información Programática",
        "subtitulo" : [
            {
                "informacionTitulo" : "a) Gasto por Categoría Programática"
            },
            {
                "informacionTitulo" : "b) Programas y Proyectos de Inversión"
            },
            {
                "informacionTitulo" : "c) Indicadores de Resultados"
            },
        ]
        
    },
    {
        "titulo" : "Cuenta Pública",
        "subtitulo" : [
            {
                "informacionTitulo" : "Información Contable CP"
            },
            {
                "informacionTitulo" : "Información Presupuestal"
            },
            {
                "informacionTitulo" : "Información Programática CP"
            },
            {
                "informacionTitulo" : "Anexos"
            },
            {
                "informacionTitulo" : "Inf. para integrar y consolidar"
            },
            {
                "informacionTitulo" : "Ley de Disciplina Anual"
            },
            {
                "informacionTitulo" : "Información adicional"
            },
            {
                "informacionTitulo" : "Información del decreto no. 90 PEEH"
            },
            {
                "informacionTitulo" : "Información de la LPCGEH"
            },
            {
                "informacionTitulo" : "Dictamen"
            },
            
        ]
    },
    {
        "titulo" : "Inventario de Bienes",
        "subtitulo" : [
            {
                "informacionTitulo" : "Inventario de Bienes Muebles"
            },
            {
                "informacionTitulo" : "Inventario de Bienes Imuebles"
            },

        ]
    },
    {
        "titulo" : "Normas Título V",
        "subtitulo" : [            
            // {
            //     "informacionTitulo" : "ANUALES"
            // },
            // {
            //     "informacionTitulo" : "SEMESTRALES"
            // },
            // {
            //     "informacionTitulo" : "TRIMESTRALES"
            // },
        ]

     
    },
    {
        "titulo" : "Ley de Disciplina Financiera",
     "subtitulo" : [
            {
                "informacionTitulo" : "ANUAL"
            },
            {
                "informacionTitulo" : "TRIMESTRAL"
            },

        ] 

    },
    {
        "titulo" : "Manual de Contabilidad Gubernamental",
        "subtitulo" : [            
           
        ]

     
    },


]

