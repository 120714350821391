import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './csociale.css';


const Csociale = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container csociale-container'>
    
        
        <div className='csociale_title'>
            <h2 className='title'>Comité de Contraloría Social del Proyecto ReactivArte 2022</h2>
            <hr className='hr-gob'/>
         </div>   

            <p>De conformidad con los lineamientos para la promoción y operación de la
            Contraloría Social, se publican aquí los documentos normativos, de difusión y
            capacitación para la vigilancia en la ejecución, ejercicio y aplicación de los
            recursos federales recibidos por la Secretaría de Cultura, en su vertiente de
            Apoyo a Instituciones Estatales de Cultura (AIEC) 2022, para el Proyecto ReactivArte.</p>
            

           <h4 className='title'>Materiales de difusión 2022</h4>
            <div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/ConvocatoriaContraloriaSocialAIEC2022.pdf" target="_blank">• Convocatoria para integrar el Comité de Contraloría Social</a>
            </div>
            <div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/IntegrantesdelcomiteCS2022.pdf" target="_blank">• Integrantes del Comité de Contraloria Social</a>
            </div>
            <div className='req6'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/TripticoContraloriaSocialAIEC2022.pdf" target="_blank">• Tríptico de difusión de actividades</a>
            </div>
            
            <h4 className='title'>Documentos Normativos 2022</h4>

            
            <div className='req8'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/EsquemaContraloriaSocialAIEC2022.pdf" target="_blank">• Esquema de Contraloria Social</a>
            </div>
            <div className='req2'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/GuiaOperativaContraloriaSocialAIEC2022.pdf" target="_blank">• Guía Operativa de Contraloría Social</a>
            </div>            
            <div className='req3'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/ProgramaAnualdeTrabajoCSAIEC2022.pdf" target="_blank">• Programa Anual de Trabajo de Contraloría Social (PATCS) </a>
            </div>
            <div className='req4'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/AnexosGuiaOperativaContraloriaSocialAIEC2022.pdf" target="_blank">• Anexos de la Guía Operativa de Contraloría Social</a>
            </div>

            <h4 className='title'>Materiales de capacitación 2022</h4>
            <div className='req7'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/CapacitacionContraloriaSocialAIEC2022.pdf" target="_blank">• AIEC y la Contraloría social</a>
            </div>             

            <h4 className='title'></h4>

            

    </div>
  )
}

export default Csociale