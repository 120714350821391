import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './inicio.css';
import { titular } from '../../json/titular';
import { Link } from 'react-router-dom';
import { carouselInicio } from '../../json/carousel';

const Inicio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Carousel bannerData={carouselInicio} search={true} name="banner-principal" />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='secretaria_logo' >
              <div className='secretaria_logo_img' style={{ backgroundImage: `url("https://cecultah.gob.mx/img/teatro_marker2.png")` }}>

              </div>
            </div>
          </div>
          <div className='secretaria_name'>
            <h1>CONSEJO ESTATAL PARA LA CULTURA Y LAS ARTES DE HIDALGO</h1>
          </div>
          <div className='secretaria_mision'>
            <h3 className='title'>MISION</h3>
            <hr className='hr-gob'/>
            <p>El Consejo Estatal para la Cultura y las Artes de Hidalgo es un Organismo Público Descentralizado encargado de crear, desarrollar, 
            promover, preservar, difundir e investigar las diversas manifestaciones culturales, artísticas y del patrimonio cultural de la Entidad, 
            así como aquellas de carácter nacional y universal, como medios para fortalecer la identidad cultural de los hidalguenses y acrecentar los valores humanos.</p>
            </div>
          <div className='secretaria_vision'>
            <h3 className='title'>VISION</h3>
            <hr className='hr-gob'/>
            <p>Ser el principal eje vinculador entre las políticas públicas culturales y 
            el desarrollo social para maximizar la identidad de los hidalguenses con su patrimonio cultural tangible e intangible.</p>
            </div>
            <div className='secretaria_objetivo1'>
            <h3 className='title'>OBJETIVO GENERAL</h3>
            <hr className='hr-gob'/>
            <p>Aplicar las políticas públicas culturales del estado, vinculándolas al desarrollo social mediante la protección, conservación y 
            difusión del patrimonio cultural, el fomento a la lectura y culturas populares, el estímulo a la creación y educación artísticas y la 
            difusión del arte y la cultura nacional y universal, para fortalecer la identidad y el desarrollo cultural integral de los hidalguenses.</p>
              <div className='btn_organigrama'>
                <button type="button" className="btn btn-primary">Organigrama</button>
                <button type="button" className="btn btn-primary">Normatividad</button>
              </div>
              <div className='secretaria-redes'>
                <a href=''><i class="fa-brands fa-facebook-f"></i></a>
                <a href=''><i class="fa-brands fa-instagram"></i></a>
                <a href=''><i class="fa-brands fa-twitter"></i></a>
              </div>
          </div>
        </div>
        <UltimasNoticias/>
        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>TITULAR DEL ORGANISMO</h3>{/*Esto es un comentario*/}
              <hr className='hr-gob'/>
            </div>
            <div className='section_notes'>
              {
                titular.map((item, index) =>(
                    <>
                      <div key={ index } className='sec_photo'>
                        <div className='img_secretario' style={{ backgroundImage: `url("${ item.imagen }")` }}>
                        </div>
                      </div>
                      <div className='sec_container'>
                        <div className='sec_name'>
                          <h3>{ item.nombre }</h3>
                        </div>
                        <div className='sec_function'>
                          <h4>Director General del Consejo Estatal para la Cultura y las Artes de Hidalgo</h4>{/*Función de la Director@ General del CECULTAH*/}
                          <p>{ item.funcion }</p>
                          <div className='btn_agenda'>
                          {/*  <Link to="Agenda">Conoce la agenda de la Directora General</Link> */}
                          </div>
                        </div>
                      </div>
                    </>
                ))
              }
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>UBICACIÓN</h3>
                <hr className='hr-gob'/>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1873.0676708269546!2d-98.733911!3d20.1284005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1a77dea87bd37%3A0x38cff3ebe4bd0b9b!2sForo%20Cultural%20Efr%C3%A9n%20Rebolledo!5e0!3m2!1ses-419!2smx!4v1718226798373!5m2!1ses-419!2smx"
              /*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d936.5656245459679!2d-98.73563714319833!3d20.123093529734906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d109e51e5c7f7b%3A0x405a00cd60c04354!2sTeatro+Hidalgo!5e0!3m2!1ses-419!2smx!4v1551907747484"*/
                      width="100%" 
                      height="300" 
                      frameborder="0" 
                      style={{border:0, marginBottom:50}} 
                      allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>   
      </div>
    </>
  )
}

export default Inicio