import React from 'react';
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Transparencia from './components/Transparencia/Transparencia';
import Servicios from './components/Servicios/Servicios';
import SistemaInstitucionalArchivos from './components/SistemaInstitucionalArchivos/SistemaInstitucionalArchivos';
import Tramites from './components/Tramites/Tramites';
import Licitaciones from './components/Licitaciones/Licitaciones';
import Invitacion from './components/Invitacion/Invitacion';
import Adquisiciones from './components/Adquisiciones/Adquisiciones';
import ArmonizacionContable from './components/ArmonizacionContable/ArmonizacionContable';
import Cocodi from './components/Cocodi/Cocodi';
import Paaas from './components/Paaas/Paaas';
import Normatividad from './components/Normatividad/Normatividad';
import Csocial from './components/Csocial/Csocial';
import Csociale from './components/Csociale/Csociale';
import Cetica from './components/Cetica/Cetica';
import AIEC from './components/AIEC/AIEC';
import Rubros from './components/Rubros/Rubros';
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import Art70 from './components/Art70/Art70';
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import EjerciciosAnteriores from "./components/EjerciciosAnteriores/EjerciciosAnteriores";
import Agenda from "./components/Agenda/Agenda";
import Error404 from "./components/Error404/Error404";
import { Armonizacion } from './json/armonizacionContable';
import { DisciplinaFinancieraAnuald } from './json/disciplinaFinancieraAnual';


function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/Transparencia" element={ <Transparencia/> } />
          <Route exact path="/Servicios" element={ <Servicios/> } />
          <Route exact path="/SistemaInstitucionalArchivos" element={ <SistemaInstitucionalArchivos/> } />
          <Route exact path="/Tramites" element={ <Tramites/> } />
          <Route exact path="/Licitaciones" element={ <Licitaciones/> } />
          <Route exact path="/Cocodi" element={ <Cocodi/> } />
          <Route exact path="/Normatividad" element={ <Normatividad/> } />
          <Route exact path="/Adquisiciones" element={ <Adquisiciones/> } />
          <Route exact path="/Invitacion" element={ <Invitacion/> } />
          <Route exact path="/Armonizacion" element={ <ArmonizacionContable data={Armonizacion} title="Armonizacion Contable" dataType="/Armonizacion" /> } />
          <Route exact path="/Disciplina" element={ <ArmonizacionContable data={DisciplinaFinancieraAnuald} title="Disciplina Financiera" dataType="/Disciplina" /> } />
          <Route exact path="/Transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/Art70" element={ <Art70/> } />
          <Route exact path="/Csocial" element={ <Csocial/> } />
          <Route exact path="/Csociale" element={ <Csociale/> } />
          <Route exact path="/AIEC" element={ <AIEC/> } />
          <Route exact path="/Cetica" element={ <Cetica/> } />
          <Route exact path="/AIEC" element={ <AIEC/> } />
          <Route exact path="/Paaas" element={ <Paaas/> } />
          <Route exact path="/Transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/Transparencia/Rubros/RubrosHistoricos" element={ <RubrosHistoricos/> } />
          <Route exact path="/EjerciciosAnteriores" element={ <EjerciciosAnteriores/> } />
          <Route exact path="/Agenda" element={ <Agenda/> } />          
          <Route exact path="*" element={<Error404 /> } />
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
