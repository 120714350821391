import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './cocodi.css';

const Cocodi = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container cocodi-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='cocodi_title'>
                    <h1>Comité de Control y Desempeño Institucional</h1>
                </div>
            </div>
        </div>
                  
        <div class="accordion" id="accordionExample">
        <div class="card border shadow mt-4 card-item-licitacion">
          
            <div class="accordion-item">
              <h1 class="accordion-header" id="headingOnePaaas2023"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023" aria-expanded="false" aria-controls="collapseOnePaaas2023">Acta de Instalación COCODI</button></h1>
              <div id="collapseOnePaaas2023" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
               
                     <ul class="list-group">
                     <li class="list-group-item">Acta de Instalación COCODI<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/ActaDeInstalaciónCOCODI.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">pdf</i></a></li>
                    </ul>


                  </div>
                 </div>
             </div>
          </div>
      </div>
        
         

         <div class="accordion" id="accordionExample1">
            <div class="card border shadow mt-4 card-item-licitacion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOnePaaas2023a"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023a" aria-expanded="false" aria-controls="collapseOnePaaas2023a">Normatividad Interna</button></h2>
              <div id="collapseOnePaaas2023a" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023a" data-bs-parent="#accordionExample1">
               
                     <ul class="list-group">
                    
                       <li class="list-group-item">Descripción de puestos plaza Base<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/NormatividadInterna/DescripciondePuestosPlazaBase.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">pdf</i></a></li>
                       <li class="list-group-item">Lineamientos internos administrativos CECULTAH 2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/NormatividadInterna/LneamientosInternosAdministrativosCecultah2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Lineamientos internos para la administración de Recursos Humanos CECULTAH 2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023//transparencia/cocodi/NormatividadInterna/LineamientosInternosParaLaAdministraciondeRHcecultah2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Plan de sucesión CECULTAH 2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/NormatividadInterna/PlanSusecionCecultah2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Promoción de la Integridad y Prevención de la Corrupción 2023<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/NormatividadInterna/ProgramaDePromocionDeLaIntegridadyPrevencionDeLaCcorrupcion2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">PROGRAMA ANUAL DE MEJORA REGULATORIA 2023<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/NormatividadInterna/PROGRAMAANUALDEMEJORAREGULATORIA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>
          </div>
         
       <h3 className='title'>2023</h3>
        

                    <div class="accordion" id="accordionExample2">
                    <div class="card border shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOnePaaas2023b"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023b" aria-expanded="false" aria-controls="collapseOnePaaas2023b">Sesiones Ordinarias COCODI</button></h2>
                    <div id="collapseOnePaaas2023b" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023b" data-bs-parent="#accordionExample2">
               
                     <ul class="list-group">
                    
                       <li class="list-group-item">Primera Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/so/ACTA 1SO_ENERO-MARZO 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Segunda Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/so/ACTA 2SO_ABRIL-JUNIO 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Tercera Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/so/ACTA 3SO_JULIO-SEPTIEMBRE 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Cuarta Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/so/ACTA SESION OCTUBRE-DICIEMBRE 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    {/*<li class="list-group-item">Plan de Tecnologias de la Infromación<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/PlanInstitucionaldeRecuperaciónencasodeDesastres2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Plan Institucional de Recuperación en caso de Desastres2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023//transparencia/cocodi/2022/so/PlandeTecnologiasdelaInformación2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Administración de Riesgos<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/ProgramadeTrabajodeAdministracióndeRiesgos.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Control Interno Modificado<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/ProgramadeTrabajodeControlInternoModificado2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}
                    </ul>
                 </div>
              </div>
           </div>
            </div>
          
      
                   <div class="accordion" id="accordionExample3">
                    <div class="card border shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOnePaaas2023c"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023c" aria-expanded="false" aria-controls="collapseOnePaaas2023c">Sesiones Extraordinarias COCODI</button></h2>
                    <div id="collapseOnePaaas2023c" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023c" data-bs-parent="#accordionExample3">
         
                     <ul class="list-group">
                    
                       <li class="list-group-item">Primera Sesión Extraordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/se/ACTA 1RA SESION EXTRAORDINARIA 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Segunda Sesión Extraordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/se/ACTA 2DA SESION EXTRAORDINARIA 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Tercera Sesión Extraordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/se/ACTA 3RA SESION EXTRAORDINARIA 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       {/*<li class="list-group-item">Cuarta Sesión Extraordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/CuartaSesionOrdinaria2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Control Interno Modificado<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/ProgramadeTrabajodeControlInterno2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>*/}                
                    </ul>
                 </div>
              </div>
           </div>
          </div>


          <div class="accordion" id="accordionExample3">
                    <div class="card border shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOnePaaas2023c"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023c" aria-expanded="false" aria-controls="collapseOnePaaas2023c">Planes de Trabajo COCODI</button></h2>
                    <div id="collapseOnePaaas2023c" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023c" data-bs-parent="#accordionExample3">
         
                     <ul class="list-group">
                    
                      <li class="list-group-item">Programa De Trabajo De Administración De Riesgos<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/pa/PROGRAMA DE TRABAJO DE ADMINISTRACIÓN DE RIESGOS (PTAR) 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Control Interno<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2023/pa/PROGRAMA DE TRABAJO DE CONTROL INTERNO (PTCI) 2023" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>            
                    </ul>
                 </div>
              </div>
           </div>
          </div>


      
            <h3 className='title'>2022</h3>
        

                    <div class="accordion" id="accordionExample2">
                    <div class="card border shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOnePaaas2023b"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023b" aria-expanded="false" aria-controls="collapseOnePaaas2023b">Sesiones Ordinarias COCODI</button></h2>
                    <div id="collapseOnePaaas2023b" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023b" data-bs-parent="#accordionExample2">
               
                     <ul class="list-group">
                    
                       <li class="list-group-item">Primera Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/PrimeraSesionOrdinaria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Segunda Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/SegundaSesionOrdinaria2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Tercera Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/TerceraSesionOrdinaria2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Cuarta Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/CuartaSesionOrdinaria2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Plan de Tecnologias de la Infromación<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/PlanInstitucionaldeRecuperaciónencasodeDesastres2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Plan Institucional de Recuperación en caso de Desastres2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023//transparencia/cocodi/2022/so/PlandeTecnologiasdelaInformación2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Administración de Riesgos<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/ProgramadeTrabajodeAdministracióndeRiesgos.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Control Interno Modificado<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/so/ProgramadeTrabajodeControlInternoModificado2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>
            </div>
          
      
                   <div class="accordion" id="accordionExample3">
                    <div class="card border shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOnePaaas2023c"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023c" aria-expanded="false" aria-controls="collapseOnePaaas2023c">Sesiones Extraordinarias COCODI</button></h2>
                    <div id="collapseOnePaaas2023c" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023c" data-bs-parent="#accordionExample3">
         
                     <ul class="list-group">
                    
                       <li class="list-group-item">Primera Sesión Extraordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2022/se/PrimeraSesionExtraordinaria.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">pdf</i></a></li>                
                    </ul>
                 </div>
              </div>
           </div>
          </div>


      
            <h3 className='title'>2021</h3>
        

                    <div class="accordion" id="accordionExample4">
                    <div class="card border shadow mt-4 card-item-licitacion">
                    <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOnePaaas2023d"><button class="accordion-button collapsed arccordion-gris" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnePaaas2023d" aria-expanded="false" aria-controls="collapseOnePaaas2023d">Sesiones Extraordinarias COCODI</button></h2>
                    <div id="collapseOnePaaas2023d" class="accordion-collapse collapse" aria-labelledby="headingOnePaaas2023d" data-bs-parent="#accordionExample4">
               
                     <ul class="list-group">
                    
                       <li class="list-group-item">Primera Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/PrimeraSesionOrdinaria2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Segunda Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/SegundaSesionOrdinaria2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Tercera Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/TerceraSesionOrdinaria2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Cuarta Sesión Ordinaria<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/CuartaSesionOrdinaria2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                       <li class="list-group-item">Programa de Trabajo de Control Interno Modificado<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2023/transparencia/cocodi/2021/so/ProgramadeTrabajodeControlInterno2021.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

        </div>

    </div>
  )
}

export default Cocodi