export const carouselInicio = [
    {
        "imagen" : "../assets/imgs/SC05.jpg",
        //"url" : "https://www.google.com"
    },
    {
        "imagen" : "../assets/imgs/Bartolome_3.jpg"
    },
    {
        "imagen" : "../assets/imgs/Bartolome_4_portada.jpg"
    },
    {
        "imagen" : "../assets/imgs/Bartolome_8.jpg"
    },
    {
        "imagen" : "../assets/imgs/Bartolome_9.jpg"
    },
    {
        "imagen" : "../assets/imgs/Bartolome_10.jpg"
    }
]