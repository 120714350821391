import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './adquisiciones.css';
import { adquisiciones_item } from '../../json/adquisicionesItem';

const Adquisiciones = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container adquisiciones-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='adquisiciones_title'>
                    <h1>Adquisiciones</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                adquisiciones_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 adquisiciones_item'>
                        <Link to={ item.adquisiciones_item_url }>{ item.adquisiciones_item_nombre }</Link>
                        <p>{ item.adquisiciones_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Adquisiciones