import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './normatividad.css';
import { normatividad_item } from '../../json/normatividadItem';

const Normatividad = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container normatividad-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='normatividad_title'>
                    <h1>Normatividad</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                normatividad_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 normatividad_item'>
                        <Link to={ item.normatividad_item_url }>{ item.normatividad_item_nombre }</Link>
                        <p>{ item.normatividad_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Normatividad