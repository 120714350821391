export const links = [
    {
        "nombre" : "Encuesta",
        "link" : "https://docs.google.com/forms/d/e/1FAIpQLSfCB3UD44xMLvMFxfBjvpkZuqZYmPkJWcja1JuamL3ZTEPibA/viewform",
       
    },


    {
        "nombre" : "Transparencia",
        "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/Transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Art. 70 Ley de Transparencia",
                "subMenuUrl" : "/Art70"
            },
            {
                "subMenuNombre" : "Rubros Anteriores",
                "subMenuUrl" : "/Transparencia/Rubros/RubrosHistoricos"
            },
            {
                "subMenuNombre" : "COCODI",
                "subMenuUrl" : "/Cocodi"
            },
            {
                "subMenuNombre" : "Sistema Institucional de Archivos",
                "subMenuUrl" : "/SistemaInstitucionalArchivos"
            },
        ]
    },
    {
        "nombre" : "Servicios",        
        "link" : "/Servicios",
        "submenu" : [
            {
                "subMenuNombre" : "Banda Sinfónica del Estado de Hidalgo",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/529"
            },
            {
                "subMenuNombre" : "Los Bohemios",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/10602"
            },
            {
                "subMenuNombre" : "Renta de espacios culturales",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/473"
            },
            {
                "subMenuNombre" : "Taquilla Cultural",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/8035"
            },
            {
                "subMenuNombre" : "Tienda Margarita Michelena.",
                "subMenuUrl" : "https://tienda.cecultah.gob.mx/"
            },
        ]
    },
    {
        "nombre" : "Trámites",
        "link" : "/Tramites",
        "submenu" : [
            {
                "subMenuNombre" : "Programa Escolarizado CAH",
                 "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/566"
            },
            {
                "subMenuNombre" : "Solicitud Beca alumnos CAH",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/7059"
            },
            {
                "subMenuNombre" : "Talleres Artisticos Esp. CAH",
                 "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/585"
            },
            {
                "subMenuNombre" : "Talleres artísticos libres CAH",
                "subMenuUrl" : "https://ruts.hidalgo.gob.mx/ver/583"
            },
        ]
    },
    {
          "nombre" : "Adquisiciones",
          "link" : "/Adquisiciones",
          "submenu" : [
            {
               "subMenuNombre" : "PAAAS CECULTAH",
                "subMenuUrl" : "/Paaas",
            },
            {
               "subMenuNombre" : "Licitaciones Públicas Nacionales",
                "subMenuUrl" : "/Licitaciones",
            },
            {
               "subMenuNombre" : "Invitacion a cuando menos tres personas",
               "subMenuUrl" : "/Invitacion",
            },
            {
               "subMenuNombre" : "Ejercicios Anteriores",
                "subMenuUrl" : "/EjerciciosAnteriores",
               
            },
        ]
    },
    {
          "nombre" : "Armonización Contable",
          "link" : "/Armonizacion",
          "submenu" : [
            
        ]
    },
    {
          "nombre" : "Normatividad",
          "link" : "/Normatividad",
          "submenu" : [
            
        ]
    },
]