import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './tramites.css';
import { tramites_item } from '../../json/tramitesItem';

const Tramites = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container tramites-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='tramites_title'>
                    <h1>Trámites</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                tramites_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 tramites_item'>
                        <Link to={ item.tramites_item_url }target='_blank'>{ item.tramites_item_nombre }</Link>
                        <p>{ item.tramites_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Tramites