import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './AIEC.css';


const AIEC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container AIEC-container'>
    
        
        <div className='AIEC_title'>
            <h2 className='title'>Programa Apoyo a Instituciones Estales de Cultura AIEC 2024</h2>
            <hr className='hr-gob'/>
         </div>   

            <p>PROYECTO 84. TRANSFORMANDO A TRAVÉS DEL ARTE.</p>
            

           <h4 className='title'>Materiales de difusión</h4>
            <div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/1. Convocatoria del Comité de Contraloría Social.pdf" target="_blank">1. Convocatoria del Comité de Contraloría Social</a>
            </div>
            <div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/2. Integrantes del Comité de Contraloría Social.pdf" target="_blank">2. Integrantes del Comité de Contraloría Social</a>
            </div>
            <div className='req6'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/3. Tríptico Contraloría Social.pdf" target="_blank">3. Tríptico Contraloría Social</a>
            </div>
            
            <h4 className='title'>Documentos Normativos</h4>

            
            <div className='req8'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/I. Esquema de Contraloría Social AIEC 2024.pdf" target="_blank">I. Esquema de Contraloría Social</a>
            </div>
            <div className='req2'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/II. Guía Operativa de Contraloría Social AIEC 2024.pdf" target="_blank">II. Guía Operativa de Contraloría Social</a>
            </div>            
            <div className='req3'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/III. Programa Anual de Trabajo Contraloría Social AIEC 2024.pdf" target="_blank">III. Programa Anual de Trabajo de Contraloría Social (PATCS)</a>
            </div>
            <div className='req4'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/IV. Anexos Guía Operativa Contraloría Social AIEC 2024.pdf" target="_blank">IV. Anexos de la Guía Operativa de Contraloría Social</a>
            </div>
            <div className='req1'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Anexo 1 Acta constitución de CCS AIEC 2024.docx" target="_blank">✓  Anexo 1</a>
            </div>
            <div className='req1'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Anexo 2 Acta sustitución de CCS CS AIEC 2024.docx" target="_blank">✓  Anexo 2</a>
            </div>
            <div className='req1'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Anexo 3 Minuta CS AIEC 2024.docx" target="_blank">✓  Anexo 3</a>
            </div>
            <div className='req1'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Anexo 4 Informe del CCS AIEC 2024.xlsx" target="_blank">✓  Anexo 4</a>
            </div>
            <div className='req1'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Anexo 5 PTIE CS AIEC 2024.xlsx" target="_blank">✓  Anexo 5</a>
            </div>
            <div className='req1'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/Anexo 6 Sesión Capacitación CS AIEC 2024.xlsx" target="_blank">✓  Anexo 6</a>
            </div>
           

            <h4 className='title'>Materiales de capacitación</h4>
            <div className='req7'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2024/transparencia/Normatividad/1. Diapositivas utilizadas en la capacitación.pdf" target="_blank">1. Diapositivas utilizadas en la capacitación</a>
            </div>             

            <h4 className='title'></h4>

            

    </div>
  )
}

export default AIEC