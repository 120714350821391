import { 
  //Información Contable
  estadoSituacionFinanciera, estadoActividades, estadoCambiosSituacionFinanciera, 
  informePasivosContingentes, notasEstadosFinancieros, estadoAnalíticoActivo, conciliacionContablePresupuestalE,
  estadoFlujosEfectivo, estadoAnaliticoDeudaOtrosPasivo, conciliacionContablePresupuestal, estadoVariacionHaciendaPublica,
  

 //Información Presupuestaria
  estadoAnaliticoIngresos, estadoAnaliticoPresupuestoEgresosCE, estadoAnaliticoPresupuestoEgresosCOG, InteresesDeuda,
  EstadoAnaliticoPresupuestoEgresosCA,EstadoAnaliticoPresupuestoEgresosCF, EndeudamientoNeto,
  EstadoAnaliticoEjercicioPresupuestoEgresos, EstadoAnaliticoPresupuestoEgresosClasificacionProgramatica, EstadoAnaliticoEjercicioPresupuestoFuenteFinanciamiento,
  EstadoFinancieroPresupuestal, edoAnaliticoPrestoEgresosClasifObjetoGasto,
  edoAnaliticodelPrestoEgresosClasifEconimica, IndicadoresPosturaFiscal,

  //Información Programática

  GastoCategoriaProgramatica, IndicadoresResultados, ProgramasProyectosInversion,

  
  ProgramaAnualEvaluaciones, ResultadoProgramaAnualEvaluaciones,

//Información Bienes
  InventarioBienesMuebles, InventarioBienesImuebles,

//Información Cuenta Publica
  CuentaPublica2022,
  InformaciónContableCP,
  informacionPresupuestalCP,
  informacionProgramaticaCP,
  anexosCP,
  infintegconsol,
  leydisciplinafinancieraCP,
  informacionadicionalCP,
  informaciondecreto90PEEH,
  informacionLPCGEH,
  ProgramaAnualAdquisiciones,
  CalendarioGastos,
  AprobacionLeyIngresosPresupuestos,
  DictameN,

//Información Normas Titulo V
  Normastitulov,  
  // informacionNormasTVA,
  // informacionNormasTVS,
  // informacionNormasTVT,
  LeyDisciplinaFinancieraAN,
  LeyDisciplinaFinancieraTM,
  ManualdeContabilidadGubernamental,
  } from "../json/anioArmonizacion";
import { BalancePresupuestarioTrim, DeudaPpasivos, DisciplinaFinancieraAnual, EdoAnaEjercPtoEgresosClasAdminTrim, EdoAnaEjercPtoEgresosClasFuncionalFinalidadFuncionTrim, EdoAnaEjercPtoEgresosClasFuncionalServiciosPersonalesCategoriaTrim, EdoAnaEjercPtoEgresosClasObjetoGastoCapConcepTrim, EdoAnaliticoIngresosDetalladoTrim, EstudActuarialesTrim, GuiaCumpLeyDFEntidadesTrim, ObrigacionesDiferentesFinanciamientoTrim, ProyeccionesEgresos, ProyeccionesIngresos, ResultadosEgresos, ResultadosIngresos, disciplinaFinancieraAnualCollapce 
  } from "../json/anioDisciplinaAnual";

export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {

    //Información Contable

    case 'b) Estado de Situación Financiera':
      datosSeleccionados = estadoSituacionFinanciera;
      break;
    case 'a) Estado de Actividades':
      datosSeleccionados = estadoActividades;
      break;
    case 'c) Estado de Variación en la Hacienda Pública':
      datosSeleccionados = estadoVariacionHaciendaPublica;
      break;
    case 'd) Estado de Cambios en la Situación Financiera':
      datosSeleccionados = estadoCambiosSituacionFinanciera;
      break;
    case 'f) Informe de Pasivos Contingentes':
      datosSeleccionados = informePasivosContingentes;
      break;
    case 'g) Notas a los Estados Financieros':
      datosSeleccionados = notasEstadosFinancieros;
      break;
    case 'h) Estado Analítico del Activo':
      datosSeleccionados = estadoAnalíticoActivo;
      break;
    case 'e) Estado de Flujos de Efectivo':
      datosSeleccionados = estadoFlujosEfectivo;
      break;
    case 'i) Estado Analítico de la Deuda y Otros Pasivos':
      datosSeleccionados = estadoAnaliticoDeudaOtrosPasivo;
      break;
    case 'j) Conciliacion entre los Ingresos Presupuestarios y Contables':
      datosSeleccionados = conciliacionContablePresupuestal;
      break;
    case 'k) Conciliacion entre los Egresos Presupuestarios y los Gastos Contables':
      datosSeleccionados = conciliacionContablePresupuestalE;
      break;
      
        
  
    //Información Presupuestaría

    case 'a) Estado Analítico de Ingresos':
      datosSeleccionados = estadoAnaliticoIngresos;
      break;
    case 'b) 2. Estado Analítico Presupuesto Egresos Clasificacion Economica':
      datosSeleccionados = estadoAnaliticoPresupuestoEgresosCE;
      break;
    case 'b) 3. Estado Analítico Presupuesto Egresos Clasificación por Objeto del Gasto':
      datosSeleccionados = estadoAnaliticoPresupuestoEgresosCOG;
      break;
    case 'c) Estado Analítico Presupuesto Egresos Clasificación por Objeto del Gasto':
      datosSeleccionados = estadoAnaliticoPresupuestoEgresosCE;
      break;
    case 'd) Intereses de la Deuda':
      datosSeleccionados = InteresesDeuda;
      break;
    case 'b) 1. Estado Analítico Presupuesto Egresos Clasificacion Administrativa':
      datosSeleccionados = EstadoAnaliticoPresupuestoEgresosCA;
      break;
    case 'b) 4. Estado Analítico Presupuesto Egresos Clasificación Funcional':
      datosSeleccionados = EstadoAnaliticoPresupuestoEgresosCF;
      break;
    case 'Indicadores de Postura Fiscal':
      datosSeleccionados = IndicadoresPosturaFiscal;
      break;
    case 'c) Endeudamiento Neto':
      datosSeleccionados = EndeudamientoNeto;
      break;
    case 'Estado Analítico del Ejercicio del Presupuesto de Egresos':
      datosSeleccionados = EstadoAnaliticoEjercicioPresupuestoEgresos;
      break;
    case 'Estado Analítico del Presupuesto de Egresos en Clasificación Programática':
      datosSeleccionados = EstadoAnaliticoPresupuestoEgresosClasificacionProgramatica;
      break;
    case 'Estado Analítico del Ejercicio del Presupuesto por Fuente de Financiamiento':
      datosSeleccionados = EstadoAnaliticoEjercicioPresupuestoFuenteFinanciamiento;
      break;
    case 'Estado Financiero Presupuestal':
      datosSeleccionados = EstadoFinancieroPresupuestal;
      break;
    case 'Estado Analítico del Presupuesto de Egresos en Clasificación por Objeto del Gasto':
      datosSeleccionados = edoAnaliticoPrestoEgresosClasifObjetoGasto;
      break; 
    case 'Estado Analítico del Presupuesto de Egresos en Clasificación Económica (Tipo de Gasto)':
      datosSeleccionados = edoAnaliticodelPrestoEgresosClasifEconimica;
      break;
    case 'e) Flujo de fondos (Indicadores de Postura Fiscal':
      datosSeleccionados = IndicadoresPosturaFiscal;
      break;      
    
    //Información Programática

    case 'a) Gasto por Categoría Programática':
      datosSeleccionados = GastoCategoriaProgramatica;
      break;
    case 'c) Indicadores de Resultados':
      datosSeleccionados = IndicadoresResultados;
      break;
    case 'b) Programas y Proyectos de Inversión':
      datosSeleccionados = ProgramasProyectosInversion;
      break;

    //Otras Obligaciones LGCG

    case 'Programa Anual de Evaluaciones':
      datosSeleccionados = ProgramaAnualEvaluaciones;
      break;
    case 'Resultado del Programa Anual de Evaluaciones':
      datosSeleccionados = ResultadoProgramaAnualEvaluaciones;
      break;

    //Inventario de Bienes

    case 'Inventario de Bienes Muebles':
      datosSeleccionados = InventarioBienesMuebles;
      break;
    case 'Inventario de Bienes Imuebles':
      datosSeleccionados = InventarioBienesImuebles;
      break;

    //Normas Título V
    case 'Normas Título V':
      datosSeleccionados = Normastitulov;
    break;
    // case 'ANUALES':
    //   datosSeleccionados = informacionNormasTVA;
    // break;
    // case 'SEMESTRALES':
    //   datosSeleccionados = informacionNormasTVS;
    // break;
    // case 'TRIMESTRALES':
    //   datosSeleccionados = informacionNormasTVT;
    // break;
    
    //Ley de Disciplina Financiera

     case 'ANUAL':
      datosSeleccionados = LeyDisciplinaFinancieraAN;
    break;
    case 'TRIMESTRAL':
      datosSeleccionados = LeyDisciplinaFinancieraTM;
    break;

    //Manual de Contabilidad Gubernamental
    case 'Manual de Contabilidad Gubernamental':
      datosSeleccionados = ManualdeContabilidadGubernamental;
    break;
      
    //Programa Anual de Adquisiciones

    //case 'Programa Anual de Adquisiciones 2022':
    //  datosSeleccionados = ProgramaAnualAdquisiciones;
    //  break;

    //Calendario de Gastos

    case 'Calendario de Gastos 2022':
      datosSeleccionados = CalendarioGastos;
      break;
    
    //Cuenta Pública

    case 'Cuenta Pública':
      datosSeleccionados = CuentaPublica2022;
      break;
    case 'Información Contable CP':
      datosSeleccionados = InformaciónContableCP;
      break;
    case 'Información Presupuestal':
      datosSeleccionados = informacionPresupuestalCP;
      break;
    case 'Información Programática CP':
      datosSeleccionados = informacionProgramaticaCP;
      break;
    case 'Anexos':
      datosSeleccionados = anexosCP;
      break;
    case 'Inf. para integrar y consolidar':
      datosSeleccionados = infintegconsol;
      break;
    case 'Ley de Disciplina Anual':
      datosSeleccionados = leydisciplinafinancieraCP;
      break;   
    case 'Información adicional':
      datosSeleccionados = informacionadicionalCP;
      break;
    case 'Información del decreto no. 90 PEEH':
      datosSeleccionados = informaciondecreto90PEEH;
      break;
    case 'Información de la LPCGEH':
      datosSeleccionados = informacionLPCGEH;
      break;
    case 'Dictamen':
      datosSeleccionados = DictameN;
      break;


    //case 'Normas (Titulo Quinto LGCG)':
     // datosSeleccionados = AprobacionLeyIngresosPresupuestos;
      //break;

    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};


//Ley de Disciplina Financiera Trimestral

export const obtenerDatosSeleccionadosDisciplina = (selectedItemIndex) => {
  let datosSeleccionadosDisciplina = [];

  switch (selectedItemIndex) {
    case 'LDF - 01 Edo. de Situación Financiera Detallado':
      datosSeleccionadosDisciplina = DisciplinaFinancieraAnual;
      break;
    case 'LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos':
      datosSeleccionadosDisciplina = DeudaPpasivos;
      break;
    case 'LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento':
      datosSeleccionadosDisciplina = ObrigacionesDiferentesFinanciamientoTrim;
      break;
    case 'LDF - 04 Balance Presupuestario':
      datosSeleccionadosDisciplina = BalancePresupuestarioTrim;
      break;
    case 'LDF - 05 Edo. Analítico de Ingresos Detallado':
      datosSeleccionadosDisciplina = EdoAnaliticoIngresosDetalladoTrim;
      break;
    case 'LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasObjetoGastoCapConcepTrim;
      break;
    case 'LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasAdminTrim;
      break;
    case 'LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasFuncionalFinalidadFuncionTrim;
      break;  
    case 'LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasFuncionalServiciosPersonalesCategoriaTrim;
      break; 
    case 'LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios':
      datosSeleccionadosDisciplina = GuiaCumpLeyDFEntidadesTrim;
      break; 
    case 'LDF - Informe sobre Estudios Actuariales':
      datosSeleccionadosDisciplina = EstudActuarialesTrim;
      break; 
    case 'LDF - Proyecciones de Ingresos':
      datosSeleccionadosDisciplina = ProyeccionesIngresos;
      break; 
    case 'LDF - Proyecciones de Egresos':
      datosSeleccionadosDisciplina = ProyeccionesEgresos;
      break;   
    case 'LDF - Resultados de Ingresos':
      datosSeleccionadosDisciplina = ResultadosIngresos;
      break; 
    case 'LDF - Resultados de Egresos':
      datosSeleccionadosDisciplina = ResultadosEgresos;
      break; 









// Ver en forma de Accordion
    case 'disciplina anual collapse':
      datosSeleccionadosDisciplina = disciplinaFinancieraAnualCollapce;
      break;
    default:
      datosSeleccionadosDisciplina = [];
  }

  return datosSeleccionadosDisciplina;
};