import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './servicios.css';
import { servicios_item } from '../../json/serviciosItem';

const Servicios = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container servicios-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='servicios_title'>
                    <h1>Servicios</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                servicios_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 servicios_item'>
                        <Link to={ item.servicios_item_url }target='_blank'>{ item.servicios_item_nombre }</Link>
                        <p>{ item.servicios_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Servicios