import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './cetica.css';


const Csociale = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container cetica-container'>
    
        
        <div className='cetica_title'>
            <h2 className='title'>CÓDIGO DE ÉTICA DE LA ADMINISTRACIÓN PÚBLICA DEL ESTADO DE HIDALGO</h2>
            <hr className='hr-gob'/>
         </div>   
            <div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/CÓDIGO DE ÉTICA DE LA ADMINISTRACIÓN PÚBLICA.pdf" target="_blank">• CEAPEH</a>
            </div>

         <div className='cetica_title'>
            <h2 className='title'>CÓDIGO DE CONDUCTA DEL CECULTAH</h2>
            <hr className='hr-gob'/>
            </div><div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/Código de Conducta del CECULTAH.pdf" target="_blank">• CCC</a>
            </div>


        <div className='cetica_title'>
            <h2 className='title'>PROTOCOLO DE DENUNCIA E INVESTIGACIÓN DE ACTOS CONTRARIOS A LA ÉTICA DEL CECULTAH</h2>
            <hr className='hr-gob'/>
         </div>
            <div className='req5'>
            <a href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/dir1/2022/normatividad/Protocolo de Denuncia e Investigación de Actos Contrarios a la Ética del CECULTAH.pdf" target="_blank">• PDIACEC</a>
            </div>   


                       

            <h4 className='title'></h4>

            

    </div>
  )
}

export default Csociale