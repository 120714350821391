import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './art70.css';
import { art70_item } from '../../json/art70item';

const Art70 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container art70-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='art70_title'>
                    <h1>Art. 70 Ley de Transparencia</h1>
                     <hr className='hr-gob'/>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                art70_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 art70_item'>
                        <Link to={ item.art70_item_pdf } target='_blank'>{ item.art70_item_nombre }</Link>
                        <p>{ item.art70_item_descripcion }</p>

                        
                    </div>
                ))

            }

           <Link className='rubros_historicos' to="RubrosHistoricos">Información Ejercicios Anteriores Art. 70 </Link>

        </div>
    </div>
  )
}

export default Art70